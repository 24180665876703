/* BHIKADIYA NIMANSHU– 1002084401
BATHULA BHAVANI– 1002129216
BANDI SAI CHANDRA - 1002114314
BODEPUDI RAM MARUTHY– 1002057165
BOBDE HARSHITH – 1002036996 */

.grid {
	padding: 0;
	margin: 0 auto;
	list-style: none;
	text-align: center;
}

.grid li {
    display: inline-block;
    width: 377px;
    margin: 0;
    padding: 3px;
    text-align: left;
    position: relative;
}

.grid figure {
	margin: 0;
	position: relative;
}

.grid figure img {
	max-width: 100%;
	display: block;
	position: relative;
}

.grid figcaption {
	position: absolute;
	top: 0;
	left: 0;
	padding: 20px;
	background: #E1009F;
	color: #fff;
}

.grid figcaption h3 {
	margin: 0;
	padding: 0;
	color: #fff;
}

.grid figcaption span:before {
	content: 'by ';
}

.grid figcaption a {
	text-align: center;
	padding: 5px 10px;
	display: inline-block;
	background: #232428;
	color: #fff;
}

.grid figcaption a:hover{
	background: #2793FD;
}
/* Individual Caption Styles */

/* Caption Style 1 */
.cs-style-1 figcaption {
	height: 100%;
	width: 100%;
	opacity: 0;
	text-align: center;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
}

.no-touch .cs-style-1 figure:hover figcaption,
.cs-style-1 figure.cs-hover figcaption {
	opacity: 1;
	-webkit-transform: translate(15px, 15px);
	-moz-transform: translate(15px, 15px);
	-ms-transform: translate(15px, 15px);
	transform: translate(15px, 15px);
}

.cs-style-1 figcaption h3 {
	margin-top: 70px;
}

.cs-style-1 figcaption span {
	display: block;
}

.cs-style-1 figcaption a {
	margin-top: 30px;
}

/* Caption Style 2 */
.cs-style-2 figure img {
	z-index: 10;
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-2 figure:hover img,
.cs-style-2 figure.cs-hover img {
	-webkit-transform: translateY(-90px);
	-moz-transform: translateY(-90px);
	-ms-transform: translateY(-90px);
	transform: translateY(-90px);
}

.cs-style-2 figcaption {
	height: 90px;
	width: 100%;
	top: auto;
	bottom: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.cs-style-2 figcaption a {
	position: absolute;
	right: 20px;
	top: 30px;
}

/* Caption Style 3 */
.cs-style-3 figure {
	overflow: hidden;
}

.cs-style-3 figure img {
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-3 figure:hover img,
.cs-style-3 figure.cs-hover img {
	-webkit-transform: translateY(-50px);
	-moz-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.cs-style-3 figcaption {
	height: 100px;
	width: 100%;
	top: auto;
	bottom: 0;
	opacity: 0;
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
	transition: transform 0.4s, opacity 0.1s 0.3s;
}

.no-touch .cs-style-3 figure:hover figcaption,
.cs-style-3 figure.cs-hover figcaption {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s;
	transition: transform 0.4s, opacity 0.1s;
}

.cs-style-3 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 4 */
.cs-style-4 li {
	-webkit-perspective: 1700px;
	-moz-perspective: 1700px;
	perspective: 1700px;
	-webkit-perspective-origin: 0 50%;
	-moz-perspective-origin: 0 50%;
	perspective-origin: 0 50%;
}

.cs-style-4 figure {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.cs-style-4 figure > div {
	overflow: hidden;
}

.cs-style-4 figure img {
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-4 figure:hover img,
.cs-style-4 figure.cs-hover img {
	-webkit-transform: translateX(25%);
	-moz-transform: translateX(25%);
	-ms-transform: translateX(25%);
	transform: translateX(25%);
}

.cs-style-4 figcaption {
	height: 100%;
	width: 50%;
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
	transition: transform 0.4s, opacity 0.1s 0.3s;
}

.no-touch .cs-style-4 figure:hover figcaption,
.cs-style-4 figure.cs-hover figcaption {
	opacity: 1;
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s;
	transition: transform 0.4s, opacity 0.1s;
}

.cs-style-4 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 5 */
.cs-style-5 figure img {
	z-index: 10;
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-5 figure:hover img,
.cs-style-5 figure.cs-hover img {
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	-ms-transform: scale(0.4);
	transform: scale(0.4);
}

.cs-style-5 figcaption {
	height: 100%;
	width: 100%;
	opacity: 0;
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	-moz-transition: -moz-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.no-touch .cs-style-5 figure:hover figcaption,
.cs-style-5 figure.cs-hover figcaption {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

.cs-style-5 figure a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 6 */
.cs-style-6 figure img {
	z-index: 10;
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-6 figure:hover img,
.cs-style-6 figure.cs-hover img {
	-webkit-transform: translateY(-50px) scale(0.5);
	-moz-transform: translateY(-50px) scale(0.5);
	-ms-transform: translateY(-50px) scale(0.5);
	transform: translateY(-50px) scale(0.5);
}

.cs-style-6 figcaption {
	height: 100%;
	width: 100%;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.cs-style-6 figcaption h3 {
	margin-top: 60%;
}

.cs-style-6 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 7 */
.cs-style-7 li:first-child { z-index: 6; }
.cs-style-7 li:nth-child(2) { z-index: 5; }
.cs-style-7 li:nth-child(3) { z-index: 4; }
.cs-style-7 li:nth-child(4) { z-index: 3; }
.cs-style-7 li:nth-child(5) { z-index: 2; }
.cs-style-7 li:nth-child(6) { z-index: 1; }

.cs-style-7 figure img {
	z-index: 10;
}

.cs-style-7 figcaption {
	height: 100%;
	width: 100%;
	opacity: 0;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: opacity 0.3s, height 0.3s, box-shadow 0.3s;
	-moz-transition: opacity 0.3s, height 0.3s, box-shadow 0.3s;
	transition: opacity 0.3s, height 0.3s, box-shadow 0.3s;
	box-shadow: 0 0 0 0px #2c3f52;
}

.no-touch .cs-style-7 figure:hover figcaption,
.cs-style-7 figure.cs-hover figcaption {
	pointer-events: auto;
	opacity: 1;
	height: 130%;
	box-shadow: 0 0 0 10px #2c3f52;
}

.cs-style-7 figcaption h3 {
	margin-top: 86%;
}

.cs-style-7 figcaption h3,
.cs-style-7 figcaption span,
.cs-style-7 figcaption a {
	opacity: 0;
	-webkit-transition: opacity 0s;
	-moz-transition: opacity 0s;
	transition: opacity 0s;
}

.cs-style-7 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.no-touch .cs-style-7 figure:hover figcaption h3,
.no-touch .cs-style-7 figure:hover figcaption span,
.no-touch .cs-style-7 figure:hover figcaption a,
.cs-style-7 figure.cs-hover figcaption h3,
.cs-style-7 figure.cs-hover figcaption span,
.cs-style-7 figure.cs-hover figcaption a {
	-webkit-transition: opacity 0.3s 0.2s;
	-moz-transition: opacity 0.3s 0.2s;
	transition: opacity 0.3s 0.2s;
	opacity: 1;
}

@media screen and (max-width: 31.5em) {
	.grid {
		padding: 10px 10px 100px 10px;
	}
	.grid li {
		width: 100%;
		min-width: 300px;
	}
}
@media(max-width:1024px){
	.grid li {
	    display: inline-block;
	    width: 311px;
	    margin: 0;
	    padding: 3px;
	    text-align: left;
	    position: relative;
	}
	figcaption span {
	    font-size: 11px;
	    margin-top: 0;
	    padding-top: 0;
	}
}
@media(max-width:800px){
	.grid li {
	    width: 358px;
	 }
}
@media(max-width:768px){
	.grid li {
	    width: 358px;
	 }
}
@media(max-width:640px){
	.grid li {
	    width: 294px;
	    margin: 0;
	    padding: 0px;
	}
	.grid figcaption {
	    padding: 14px;
	}
}
@media(max-width:480px){
	.grid li {
	    width: 371px;
	}
	.grid {
	    padding: 0px 0px 47px 0px;
	}
}
@media (max-width: 384px){
	.grid li {
	    width: 326px;
	}
}
@media (max-width: 375px){
	.grid li {
	    width: 326px;
	}
}
@media(max-width:320px){
	.grid figure img {
	    max-width: 100%;
	}
	.cs-style-5 figcaption {
	    width: 100%;
	}
	.grid li {
	    min-width: 271px !important;
	}
	.grid li {
	    width: 266px !important;
	}
	.grid figcaption {
	    padding: 11px;
	}
}